<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <b v-if="! isLegalType">ІПН:</b>
                <b v-else>ЄДРПОУ:</b>
                <span>{{insured.identification_number}} </span>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <b v-if="! isLegalType">ПІБ:</b>
                <b v-else>Компанія:</b>
                <span>{{insured.fio}} </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'InsuredsListItem',
    props: ['insured', 'isLegalType'],
}

</script>
